import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Center, Heading } from '@chakra-ui/react';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V2';

import { BG_PRIMARY } from '@/constants/colors';
import { BG_COVER } from '@/constants/assets';
import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST } from '@/constants/index';

import useIsDesktopView from '@/hooks/useIsDesktopView';
import useInvitation from '@/hooks/useInvitation';
import { useGuest } from '@/context/guest';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const { isLoading } = useGuest();
  const isInvitation = useInvitation();
  const isDekstopView = useIsDesktopView();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box minHeight="100vh" backgroundColor={BG_PRIMARY}>
      <Box
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="bottom center"
        bgImage={`url(${BG_COVER})`}
        padding="2rem"
        color="white"
        marginBottom="-170px"
        boxShadow="inset 0 0 0 2000px rgb(0 0 0 /15%)"
      >
        <Heading
          marginTop="90px"
          textColor="secondaryColorText"
          fontWeight="normal"
          fontSize="10px"
          textAlign="center"
          letterSpacing="3px"
          marginBottom="8px"
        >
          {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
        </Heading>
        <Box
          textAlign="center"
          textColor="secondaryColorText"
          textTransform="uppercase"
          letterSpacing="4px"
        >
          <Heading fontWeight="normal" fontSize="md">
            {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} & {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
          </Heading>
          {false && (
            <Heading fontWeight="normal" fontSize="3xl" marginTop={isDekstopView ?"-16px" :"-20px"}>
              {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
            </Heading>
          )}
        </Box>
      </Box>
      <Box transform="translateY(-5px)">
        <Center>
          <ScrollToDown
            loaded={loaded}
            loading={loading}
            marginTop="16px"
            onClick={handleClickDetail}
          />
        </Center>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
