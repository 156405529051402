export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  partnerName: undefined,
  numberChildren: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgAlternative',
  color: 'black',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'body',
  _placeholder: { color: 'black' },
  _focus: { borderColor: 'mainColorText' },
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgAlternative',
  color: 'black',
  fontFamily: 'body',
  fontSize: 'md',
  _focus: { borderColor: 'mainColorText' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0',
  fontFamily: 'body',
  marginTop: '24px',
};
