import React from 'react';
import { Box, Center, Text, Link, Heading, Divider } from '@chakra-ui/react';
import Image from '@/components/Common/LazyImage';
import useInvitation from '@/hooks/useInvitation';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_INVITATO } from '@/constants';
import { IMG_AYAT, LOGO_INVITATO } from '@/constants/assets';

import txtWording from './locales';
import useLang from '@/hooks/useLang';
import {
  ENABLE_COLLABORATION,
  MUSLIM_INVITATION,
  SELECTED_VENDOR_COLLABORATION,
} from '@/constants/feature-flags';

function FooterSection() {
  const invitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" paddingTop="24px">
      <Box textAlign="center" color="mainColorText">
        {false && (
          <Box padding="42px 24px">
            <Text fontFamily="body" fontSize="md">
              {MUSLIM_INVITATION ? txtWording.ayatMuslim[lang] : txtWording.ayat[lang]}
            </Text>
            <Text>
              {MUSLIM_INVITATION ? txtWording.ayatNameMuslim[lang] : txtWording.ayatName[lang]}
            </Text>
          </Box>
        )}
        <Image src={IMG_AYAT} loading="lazy" width="100%" />
      </Box>
      <Box padding="42px 18px" textAlign="center" minHeight="80vh" bgColor="bgPrimary">
        <Center height="80vh">
          <Box>
            <Heading color="mainColorText" textAlign="center" letterSpacing="2px" fontSize="2xl">
              SINCERE LOVE, <br />#ADIterLENA
            </Heading>
            <Text color="mainColorText" fontSize="md" padding="12px 12px">
              {invitation
                ? txtWording.closingInvitation[lang]
                : txtWording.closingAnnouncement[lang]}
            </Text>
          </Box>
        </Center>
      </Box>
      <Box
        padding="24px 0"
        bgColor="bgSecondary"
        width="100%"
        color="secondaryColorText"
        textAlign="center"
      >
        <Center>
          <Link href={URL_INVITATO} target="_blank">
            <Image src={LOGO_INVITATO} maxWidth="110px" margin="0 0 8px 0" />
          </Link>
        </Center>
        <Text textAlign="center" fontSize="sm">
          Designed by @clarissandriany
        </Text>
        <Link href={URL_INVITATO} target="_blank">
          <Text textAlign="center" fontSize="sm">
            Created with Love by Invitato
          </Text>
        </Link>
        {ENABLE_COLLABORATION && (
          <Box margin="-4px 0 8px">
            <Center>
              <Divider
                margin="16px 0"
                maxWidth="30%"
                height="1px"
                bgColor="secondaryColorText"
                color="secondaryColorText"
                border="none"
                boxShadow="none"
                borderBottomWidth="none"
              />
            </Center>
            <Center>
              <Link
                href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                target="_blank"
              >
                <Image
                  src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                  {...SELECTED_VENDOR_COLLABORATION.imgProps}
                />
              </Link>
            </Center>
            <Center>
              <Text color="secondaryColorText" align="center" fontSize="xs">
                In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
              </Text>
            </Center>
          </Box>
        )}
        <Text fontSize="sm">
          {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
        </Text>
        <Link textAlign="center" href={SOUND_URL} fontSize="sm" isExternal>
          {`Song by ${SOUND_BY}`}
        </Link>
      </Box>
    </Box>
  );
}

export default FooterSection;