import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import useInvitation from '@/hooks/useInvitation';
import DetailCard from './DetailCard';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
// adding comment for trigger build
import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';
import txt from './locales';
import useLang from '@/hooks/useLang';
import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang();
  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Box bgColor="bgPrimary">
      <Box padding="0 0 12px 0" {...rest}>
        {MUSLIM_INVITATION ? (
          <Text textAlign="center" padding="0 24px" color="mainColorText">
            {isInvitation ? (
              <span dangerouslySetInnerHTML={{ __html: txt.openingMuslimInvitation[lang] }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: txt.openingMuslimAnnouncement[lang] }} />
            )}
          </Text>
        ) : (
          <Text textAlign="center" padding="0 24px" color="mainColorText">
            {isInvitation ? (
              <span dangerouslySetInnerHTML={{ __html: txt.openingGeneralInvitation[lang] }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: txt.openingGeneralAnnouncement[lang] }} />
            )}
          </Text>
        )}
        <DetailCard
          fullName={IS_BOY_FIRST ? BOY_NAME : GIRL_NAME}
          imgUrl={IS_BOY_FIRST ? IMG_MAN : IMG_GIRL}
          instagramId={IS_BOY_FIRST ? IG_BOY : IG_GIRL}
          description={IS_BOY_FIRST ? BOY_PARENT_NAME : GIRL_PARENT_NAME}
        />
        <Text
          textAlign="center"
          color="mainColorText"
          fontFamily="heading"
          fontSize="4xl"
          margin="-24px 0 -12px"
        >{`&`}</Text>
        <DetailCard
          fullName={IS_BOY_FIRST ? GIRL_NAME : BOY_NAME}
          // imgUrl={IS_BOY_FIRST ? IMG_GIRL : IMG_MAN}
          instagramId={IS_BOY_FIRST ? IG_GIRL : IG_BOY}
          description={IS_BOY_FIRST ? GIRL_PARENT_NAME : BOY_PARENT_NAME}
        />
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);
