import React, { useMemo, useState, useCallback } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Heading,
  useToast,
  Text,
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';

import { txtForm } from './locales';
import { HEADING_STYLES } from '@/constants/colors';

import { useGuest } from '@/context/guest';
import useFormFields from '@/hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';
import generateShift from '@/hooks/useShift';

import { RSVP_STATUS, API_ATTEND_INFO } from '@/constants/api';
import {
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  ENABLE_QR_INVITATION,
  RSVP_ENABLE_ATTEND_INFO,
  ENABLE_SHIFT_TIME_RECEPTION,
} from '@/constants/feature-flags';
import {
  ERROR_TYPE,
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
} from './types';
import { txtTitle } from './locales';

import { MemoPhoneNumberList, MemoPersonalizeList } from './Utils';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();
  const shiftTime = generateShift(guest.shift);

  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();

  const filteredEventInfo = useMemo(() => {
    if (!RSVP_ENABLE_ATTEND_INFO || !ENABLE_SHIFT_TIME_RECEPTION) return [];

    return API_ATTEND_INFO.filter((item) => item.shift.includes(guest.shift));
  }, [guest.shift]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: isRegistered ? guest.name : '',
    partner_name: guest.partner_name && guest.partner_name !== '-' ? guest.partner_name : '',
    number_children:
      guest.number_children && guest.number_children !== '-' ? guest.number_children : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.total_guest || 1,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
    attend_info: RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
    accommodation:
      guest.accommodation && guest.accommodation !== '-' ? guest.accommodation : 'Yes, please',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txtForm.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const {
      name,
      partner_name,
      number_children,
      address,
      phone_number,
      total_guest,
      is_attended,
      email,
      attend_info,
      accommodation,
    } = formFields;
    // reset error state

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txtForm.required[lang] };
    }

    if (total_guest > 1 && !partner_name) {
      currentErrorType = { ...currentErrorType, partnerName: txtForm.required[lang] };
    }

    if (is_attended === TYPE.YES && (!number_children || number_children === '-')) {
      currentErrorType = { ...currentErrorType, numberChildren: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txtForm.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txtForm.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txtForm.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txtForm.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone ||
      currentErrorType.numberChildren ||
      currentErrorType.partnerName
    ) {
      setErrorType(currentErrorType);
      return;
    }

    setIsLoading(true);
    const guestPayload = {
      name,
      partnerName: is_attended === TYPE.YES && total_guest > 1 ? partner_name : '-',
      numberChildren: is_attended === TYPE.YES ? number_children : '-',
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      attendInfo: is_attended === TYPE.YES ? attend_info : '',
      accommodation: is_attended === TYPE.YES && guest.shift === 2 ? accommodation : '-',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txtForm.msgSuccess[lang]);
          onUpdateGuest(guestCode, guestPayload.name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txtForm.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txtForm.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
      },
    });
  };

  return (
    <Box padding="0 0 16px 0" bgColor="bgPrimary" {...rest}>
      <Box bgColor="bgPrimary">
        <Box padding="8px">
          <WithAnimation>
            <Box padding="24px" width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center" color="mainColorText">
                <Heading {...HEADING_STYLES} paddingLeft="0px" textAlign="center" marginTop="16px">
                  RSVP
                </Heading>
                <Text fontSize="md" margin="16px 0 24px 0">
                  {txtTitle[lang]}
                </Text>
              </Box>

              {/* Form Sections - Name */}
              {RSVP_ENABLE_NAME && (
                <FormControl margin="12px 0 8px 0" isInvalid={errorType.name}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                  <FormLabel fontStyle={'italic'}>*) {txtForm.nameHelper[lang]}</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.name}
                    onChange={createChangeHandler('name')}
                    disabled={isRegistered}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Partner */}
              {formFields.is_attended === TYPE.YES && (
                <MemoPersonalizeList
                  lang={lang}
                  totalQuota={guest.guest_quota}
                  value={formFields.total_guest}
                  onChange={createChangeHandler('total_guest')}
                />
              )}

              {/* Form Sections - Name of Partners */}
              {formFields.is_attended === TYPE.YES && formFields.total_guest > 1 && (
                <FormControl margin="12px 0 8px 0" isInvalid={errorType.partnerName}>
                  <FormLabel {...FORM_LABEL_PROPS}>Name of Partners</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="Ex: Daniel, Angelica"
                    value={formFields.partner_name}
                    onChange={createChangeHandler('partner_name')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.partnerName}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Number of Children */}
              <FormControl margin="12px 0 8px 0" isInvalid={errorType.numberChildren}>
                <FormLabel {...FORM_LABEL_PROPS}>
                  Children are warmly invited to our kid-friendly wedding reception. Please comment
                  how many will attend:
                </FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={formFields.number_children}
                  onChange={createChangeHandler('number_children')}
                />
                <FormErrorMessage marginTop="4px">{errorType.numberChildren}</FormErrorMessage>
              </FormControl>

              {/* Form Sections - Phone Number */}
              <MemoPhoneNumberList
                value={formFields.phone_number}
                createChangeHandler={createChangeHandler}
                lang={lang}
                selectedCountryCode={selectedCountryCode}
                errorPhone={errorType.phone}
                handleCountryCodeChange={handleCountryCodeChange}
              />

              {/* Form Sections - Address */}
              {RSVP_ENABLE_ADDRESS && (
                <FormControl margin="8px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.address}
                    onChange={createChangeHandler('address')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Email */}
              {RSVP_ENABLE_EMAIL && (
                <FormControl margin="8px 0" isInvalid={errorType.email}>
                  <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.email}
                    onChange={createChangeHandler('email')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={formFields.is_attended}
                  onChange={createChangeHandler('is_attended')}
                >
                  <option value={TYPE.YES} style={{ color: 'black' }}>
                    {txtForm.willAttend[lang]}
                  </option>
                  <option value={TYPE.NO} style={{ color: 'black' }}>
                    {txtForm.noAttend[lang]}
                  </option>
                </Select>
              </FormControl>

              {RSVP_ENABLE_ATTEND_INFO &&
                shiftTime === '5.00 PM WIB' &&
                formFields.is_attended === TYPE.YES && (
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.attendInfo[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={formFields.attend_info}
                      onChange={createChangeHandler('attend_info')}
                    >
                      {filteredEventInfo.map((item) => {
                        return (
                          <option key={item.value} value={item.value} style={{ color: 'black' }}>
                            {item.title}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

              {/* Form Sections - Accommodation */}
              {formFields.is_attended === TYPE.YES && guest.shift === 2 && (
                <FormControl margin="8px 0">
                  <FormLabel {...FORM_LABEL_PROPS}>
                    We are delighted to offer our guests a complimentary room from 27th till the
                    29th of September, 2024 at a designated hotel in Surabaya:{' '}
                  </FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={formFields.accommodation}
                    onChange={createChangeHandler('accommodation')}
                  >
                    <option value={'Yes, please'} style={{ color: 'black' }}>
                      {'Yes, please'}
                    </option>
                    <option
                      value={'No, I will arrange my accommodation'}
                      style={{ color: 'black' }}
                    >
                      {'No, I will arrange my accommodation'}
                    </option>
                  </Select>
                </FormControl>
              )}
              <Center>
                <Button
                  isFullWidth
                  color="secondaryColorText"
                  fontWeight="light"
                  colorScheme="green"
                  isLoading={isLoading}
                  marginTop="24px"
                  size="md"
                  type="button"
                  bgColor="bgSecondary"
                  onClick={onSubmitForm}
                  textAlign="left"
                  fontFamily="body"
                  justifyContent="left"
                  borderRadius="0"
                >
                  {txtForm.submit[lang]}
                </Button>
              </Center>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
